<template>
    <div class="element__calendar">
        <div class="datepicker-trigger">
            <div class="date-input">
                <input
                    id="datepicker-trigger"
                    type="text"
                    placeholder="Туда"
                    :value="formatDates(dateOne)"
                    readonly
                >
                <img src="../../assets/calendar-icon.svg" class="calendar-icon">
            </div>
            <div class="date-input date-second-input">
                <input
                    type="text"
                    placeholder="Обратно"
                    :value="formatDates(dateTwo)"
                    readonly
                    @click.prevent="triggerDatepicker2"
                    @blur = "onBlur"
                >
                <img v-if="dateTwo" class="calendar-icon close-icon"
                     style="width: 14px; height: 14px;"
                     src="../../assets/close-icon.svg"
                     @click="changeDirection">
                <img v-else src="../../assets/calendar-icon.svg" class="calendar-icon">
            </div>
            <AirbnbStyleDatepicker
                :trigger-element-id="'datepicker-trigger'"
                :mode="'range'"
                :fullscreen-mobile="true"
                :date-one="dateOne"
                :date-two="dateTwo"
                :offsetY="3"
                @date-one-selected="dateOneSelected"
                @date-two-selected="dateTwoSelected"
                :close-after-select="true"
                :trigger="trigger"

                :show-shortcuts-menu-trigger="false"
                :min-date="getYesterday()"
                :endDate="addYear()"
                @apply="changeDirection"
                @closed="close"
                ref="airbnbCalendar"
            />
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    import format from 'date-fns/format'
    import ru from 'date-fns/locale/ru'
    import isAfter from 'date-fns/is_after'
    import addYears from 'date-fns/add_years'
    import subDays from 'date-fns/sub_days'
    import parse from 'date-fns/parse'

    export default {
        props: ['mode'],
        data() {
            return {
                dateFormat: 'D MMM, dd',
                dateOne: this.checkDate('dep'),
                dateTwo: this.checkDate('arr'),
                trigger: false
            }
        },
        mounted() {
          //this.checkDate()
        },
        methods: {
            formatDates(date) {
                let formattedDates = '';
                if (date) {
                    formattedDates = format(date, this.dateFormat, {locale: ru})
                }
                return formattedDates
            },
            dateOneSelected(val) {
                this.dateOne = val;
                this.$emit('dateOneSelected', {type: 'departure', date: val});
                if (this.dateTwo && isAfter(parse(this.dateOne), parse(this.dateTwo))) {
                    this.dateTwo = this.dateOne;
                }
                //this.$store.dispatch('searchStatus/SET_DIRECTION', { id: 2});
            },
            checkDate(ty) {
                let today = new Date();
                let parsed_today = parse(new Date());
                let date;
                let parsed_date;
                if (ty === 'dep') {
                    date = this.$store.getters.getDateDep;
                    parsed_date = parse(this.$store.getters.getDateDep);
                } else {
                    if (this.$store.getters.get_direction.id === 2) {
                        date = this.$store.getters.getDateArr;
                        parsed_date = parse(this.$store.getters.getDateArr);
                    } else {
                        return ''
                    }
                }
                if (isAfter(parsed_date, parsed_today)) {
                    return date
                } else {
                    return today
                }
            },
            dateTwoSelected(val) {
                if (val) {
                    console.log(val)
                    this.$store.dispatch('SET_DIRECTION', { id: 2});
                    this.dateTwo = val;
                    this.$emit('dateTwoSelected', {type: 'arrival', date: val});
                    this.trigger = false;
                }
            },
            triggerDatepicker() {
                this.trigger = !this.trigger
                //this.$refs.airbnbCalendar.selectDate(this.dateOne);
            },
            triggerDatepicker2() {
                this.$refs.airbnbCalendar.selectDate(this.dateOne);
                this.trigger = !this.trigger
            },
            changeDirection() {
                this.dateTwo = '';
                this.$store.dispatch('SET_DIRECTION', { id: 1});
            },
            addYear() {
                return addYears(new Date(), 1)
            },
            getYesterday() {
                return subDays(new Date(), 1)
            },
            close() {
                setTimeout(() => {
                    if (this.dateTwo !== '') {
                        console.log('lol');
                        this.$store.dispatch('SET_DIRECTION', { id: 2});
                        // this.$refs.airbnbCalendar.selectDate(this.dateOne);

                    } else {
                        console.log('kek');

                        this.$store.dispatch('SET_DIRECTION', { id: 1});
                        // this.$refs.airbnbCalendar.selectDate(this.dateTwo);
                    }
                }, 200)
            },
            onBlur() {
                this.trigger = false
            }
        }
    }
</script>

