<template>
    <b-dropdown class="dropdown dropdown-passengers" id="dd-passengers" :no-caret="true" variant="outline" >
        <template v-slot:button-content>
            <div class="input-select-class">
                <div>
                    <p>{{ counter }} {{ (counter > 1) ? 'пассажира' : 'пассажир' }}</p>
                    <span>{{ flightClass.id === 1 ? 'Эконом' : 'Бизнес' }}</span>
                </div>
                <img src="../../assets/bottom-array.svg" alt="">
            </div>
        </template>
        <div class="dropdown-classes">
            <div class="dropdown-class" @click="changeTab(1)" :class="{ active: tabCounter == 1 }">Эконом</div>
            <div class="dropdown-class dropdown-class-center"
                 @click="changeTab(2)" :class="{ active: tabCounter == 2 }">Бизнес</div>
           <!-- <div class="dropdown-class" @click="changeTab(3)" :class="{ active: tabCounter == 3 }">{{ $tc('dropdowns.class_y') }}</div>-->
        </div>
            <div class="dropdown-item--secondary">
                <span>Взрослые</span>
                <div class="counter">
                    <div class="counter__sign" :class="{'is-disabled': pnr[0].qt === 1}" @click="deductPassenger(0)">
                        <img src="../../assets/minus-icon.svg" alt=""></div>
                    <div class="counter__number">{{ pnr[0].qt }}</div>
                    <div class="counter__sign" @click="addPassenger(0)" :class="{'is-disabled': disabled}">
                        <img src="../../assets/plus-icon.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="dropdown-item--secondary">
                <span>Дети<span>От 2 до 12 лет</span></span>
                <div class="counter">
                    <div class="counter__sign" :class="{'is-disabled': pnr[1].qt === minValue}" @click="deductPassenger(1)">
                        <img src="../../assets/minus-icon.svg" alt="">
                    </div>
                    <div class="counter__number">{{ pnr[1].qt }}</div>
                    <div class="counter__sign" :class="{'is-disabled': disabled}" @click="addPassenger(1)">
                        <img src="../../assets/plus-icon.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="dropdown-item--secondary">
                <span>Младенцы <span>До 2 лет</span></span>
                <div class="counter">
                    <div class="counter__sign" :class="{'is-disabled': pnr[2].qt === minValue}" @click="deductPassenger(2)">
                        <img src="../../assets/minus-icon.svg" alt="">
                    </div>
                    <div class="counter__number">{{ pnr[2].qt }}</div>
                    <div class="counter__sign" :class="{'is-disabled': disabled || (pnr[2].qt >= pnr[0].qt)}" @click="addPassenger(2)">
                        <img src="../../assets/plus-icon.svg" alt="">
                    </div>
                </div>
            </div>
    </b-dropdown>
</template>
<script>
    export default {
        name: 'DropdownPassengers',
        data () {
          return {
              minValue: 0,
              maxValue: 9,
              tabCounter: 1,
              pnr: JSON.parse(JSON.stringify(this.$store.state.passengers.passengers))
          }
        },
        computed: {
            flightClass () { return this.$store.getters.get_flyClass },
            counter() {
              let count = 0;
              this.pnr.forEach(el => {
                  count +=el.qt
              });
              return count
            },
            disabled () {
              return this.counter >= this.maxValue
            }
        },
        methods: {
            addPassenger (index) {
                if (index === 2 && this.pnr[index].qt >= this.pnr[0].qt) {
                    return
                } else if (this.maxValue > this.counter) {
                    this.pnr[index].qt++
                    this.$store.commit('ADD_PASSENGERS', index);
                }
            },
            deductPassenger(index) {
                if (index === 0 && this.pnr[index].qt === 1) {
                    return
                } else if (index === 0 && this.pnr[index].qt <= this.pnr[2].qt) {
                    this.pnr[2].qt--
                    this.pnr[index].qt--
                    this.$store.commit('DEDUCT_PASSENGERS', 2);
                    this.$store.commit('DEDUCT_PASSENGERS', index);
                }
                else if (this.pnr[index].qt > this.minValue) {
                    this.pnr[index].qt--
                    this.$store.commit('DEDUCT_PASSENGERS', index);
                }
                this.$emit('amountPnr', this.pnr)
            },
            changeTab(number) {
                this.tabCounter = number
                this.$store.dispatch('SET_FLY_CLASS', { id: number});
                if (number === 3) {
                    this.$store.dispatch('SET_FLY_CLASS', { id: 1});
                }
            }
        }
    }
</script>
