const state = () => ({
    direction: { id: 1 },
    flyClass: { id: 1 },
    aviaCompany: null,
    searchRequestData: null
});

const getters = {
    get_direction: state => state.direction,
    get_flyClass: state => state.flyClass,
    get_aviaCompany: state => state.aviaCompany,
    getSearchRequest (state) {
        return state.searchRequestData
    },
};

const mutations = {
    SET_DIRECTION(state, direction = null) {
        state.direction = direction;
    },
    SET_PASSENGERS(state, passengers = null) {
        state.passengers = passengers;
    },
    SET_FLY_CLASS(state, flyClass = null) {
        state.flyClass = flyClass;
    },
    SET_AVIA_COMPANY(state, aviaCompany = null) {
        state.aviaCompany = aviaCompany;
    },
    setSearchRequest(state, searchRequest = null) {
        state.searchRequestData = searchRequest;
    },
};

const actions = {
    SET_DIRECTION: ({ commit }, direction = null) => {
        commit('SET_DIRECTION', direction);
    },
    SET_PASSENGERS: ({ commit }, passengers = null) => {
        commit('SET_PASSENGERS', passengers);
    },
    SET_FLY_CLASS: ({ commit }, flyClass = null) => {
        commit('SET_FLY_CLASS', flyClass);
    },
    SET_AVIA_COMPANY: ({ commit }, aviaCompany = null) => {
        commit('SET_AVIA_COMPANY', aviaCompany);
    },
    setSearchRequest: ({ commit }, searchRequestData = null) => {
        commit('setSearchRequest', searchRequestData);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
