const state = () => ({
  sessionId: null,
  hashCode: null,
  sequence_number: null,
  book: null,
  combinationId: null,
  ticketsNotFound: false,
    fareHash: null,

});

const getters = {
  getSessionId: state => state.sessionId,
  getHashCode: state => state.hashCode,
  getSequenceNumber: state => state.sequence_number,
  getBook: state => state.book,
    getCombinationId: state => state.combinationId,
    getFareHash: state => state.fareHash
};

const mutations = {
  SET_SESSION_ID(state, session_id = null) {
    state.sessionId = session_id;
  },
  SET_HASH_CODE(state, hash_code = null) {
    state.hashCode = hash_code;
  },
  SET_SEQUENCE_NUMBER(state, sequence_number = null) {
    state.sequence_number = sequence_number;
  },
  SET_BOOK(state, book = null) {
    state.book = book;
  },
  SET_COMBINATION_ID(state, combinationId = null) {
    state.combinationId = combinationId;
  },
  SET_TICKETS_NOT_FOUND(state, ticketsNotFound) {
    state.ticketsNotFound = ticketsNotFound;
  },
    SET_FARE_HASH(state, fareHash = null) {
        state.fareHash = fareHash;
    },
};

const actions = {
  SET_SESSION_ID: ({ commit }, session_id = null) => {
    commit('SET_SESSION_ID', session_id);
  },
  SET_HASH_CODE: ({ commit }, hash_code = null) => {
    commit('SET_HASH_CODE', hash_code);
  },
  SET_SEQUENCE_NUMBER: ({ commit }, sequence_number = null) => {
    commit('SET_SEQUENCE_NUMBER', sequence_number);
  },
  SET_BOOK: ({ commit }, book = null) => {
    commit('SET_BOOK', book);
  },
  SET_COMBINATION_ID: ({ commit }, combinationId = 0) => {
    commit('SET_COMBINATION_ID', combinationId);
  },
  SET_TICKETS_NOT_FOUND: ({ commit }, ticketsNotFound) => {
    commit('SET_TICKETS_NOT_FOUND', ticketsNotFound);
  },
  async checkTicket({ commit }, data) {
    try {
      const res = await this.$axios.$post('check', data);
      return res.sts === 'success'
    } catch (e) {
      console.log(e)
    }
  },
    SET_FARE_HASH: ({ commit }, fareHash = null) => {
        commit('SET_FARE_HASH', fareHash);
    },
};

export default {
  state,
  getters,
  actions,
  mutations
};
