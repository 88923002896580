const state = () => ({
    search_flight_body: null,
    ticket: null
});

const getters = {
    get_search_flight_body: state => state.search_flight_body,
    get_ticket: state => state.ticket,
    getCheepest: (state, getters) => {
        if (state.search_flight_body !== null && state.search_flight_body.hasOwnProperty('tkts') && state.search_flight_body.tkts.length >= 1) {
            return getters.get_search_flight_body.tkts.filter(e => e.id === 'cheap')
        }
    },
    getFastest: (state, getters) => {
        if (state.search_flight_body !== null && state.search_flight_body.hasOwnProperty('tkts') && state.search_flight_body.tkts.length >= 1) {

            return getters.get_search_flight_body.tkts.filter(e => e.id === 'fast')
        }
    },
    getOther: (state, getters) => {
        if (state.search_flight_body !== null && state.search_flight_body.hasOwnProperty('tkts') && state.search_flight_body.tkts.length >= 1) {
            return getters.get_search_flight_body.tkts.filter(e => e.id === 'any')
        }
    },
    getAll: (state, getters) => {
        if (state.search_flight_body !== null && state.search_flight_body.hasOwnProperty('tkts') && state.search_flight_body.tkts.length >= 1) {
            return getters.get_search_flight_body.tkts
        }
    }
};

const mutations = {
    SET_SEARCH_FLIGHT_BODY(state, search_flight_body = null) {
        state.search_flight_body = search_flight_body;
    },
    SET_TICKET(state, ticket = null) {
        state.ticket = ticket;
    }
};

const actions = {
    async searchTickets ({ commit, dispatch }, requestData = null) {
        const res = await this.$axios.$post('search', requestData);
        return dispatch('notifications/handle', res, { root: true });
    },
    async getTicket ({ commit, dispatch }, requestData = null) {
        const res = await this.$axios.$post('book', requestData);
        return dispatch('notifications/handle', res, { root: true });
    },
    async payTicket ({ commit, dispatch }, requestData = null) {
        const res = await this.$axios.$post('pay', requestData);
        return dispatch('notifications/handle', res, { root: true });
    },
    async getRules ({ commit }, requestData = null) {
        try {
            return await this.$axios.$post('rules', requestData);
        } catch (error) {
            return error.response
        }
    },
    async cancelBook ({ commit, dispatch }, requestData = null) {
        const res = await this.$axios.$post('cancel', requestData);
        return dispatch('notifications/handle', res, { root: true });
    },
    async refundBook ({ commit, dispatch }, requestData = null) {
        const res = await this.$axios.$post('refund', requestData);
        return dispatch('notifications/handle', res, { root: true });
    },
    SET_SEARCH_FLIGHT_BODY: ({ commit }, tickets = null) => {
        commit('SET_SEARCH_FLIGHT_BODY', tickets);
    },
    SET_TICKET: ({ commit }, ticket = null) => {
        commit('SET_TICKET', ticket);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
