<template>
    <b-modal size="md" :id="'expiredTimeModal'" tabindex="-1" role="dialog" :hide-header="true" :hide-footer="true" aria-hidden="true">
      <div class="modal-content">
          <div class="modal-logo">
              <img src="../../assets/logo-transavia.png" alt="" class="logo">
              <i @click="hide" class="fa fa-times" aria-hidden="true"></i>
          </div>
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>
        </div>
        <div class="modal-footer">
<!--          <button class="btn btn-primary" @click="refresh" v-if="doRefresh">{{ $tc('refresh') }}</button>-->
          <button @click="toMain" class="btn btn-primary">Новый поиск</button>
        </div>
      </div>
    </b-modal>
</template>
<script>
    export default {
        props: ['title', 'resData', 'doRefresh'],
        methods: {
            refresh() {
                // this.$emit('refresh')
                window.location.reload();
            },
            open() {
                this.$bvModal.show('expiredTimeModal')
            },
            hide () {
                this.$bvModal.hide('expiredTimeModal')
            },
            toMain() {
                this.$router.push('/')
                this.hide()
            }
        }
    }
</script>
<style scoped>
    .btn-primary {
        width: 200px;
    }
    .modal-footer {
        justify-content: center;
    }
    .modal-footer .btn {
        background-color: #418ad4;
    }
    .logo {
        width: 175px;
        padding: 10px 0 0 10px;
    }
    .modal-logo {
        display: flex;
        justify-content: space-between;
    }
    .modal-logo .fa-times {
        color: #418ad4;
        padding: 10px 10px 0 0;
        font-size: 30px;
    }
</style>
