import Moment from 'moment';
import axios from 'axios';

let baseUrl = 'https://avia.test.transavia.kz/api/v1/avia/';

const state = () => ({
  departureCity: null,
  departureCode: null,
  arrivalCity: null,
  arrivalCode: null,
  dateDep: Moment()
      .format('YYYY-MM-DD'),
  dateArr: Moment()
    .add(1, 'days')
    .format('YYYY-MM-DD')
});

const getters = {
  getDepCity: state => state.departureCity,
  getArrCity: state => state.arrivalCity,
  getDepCode: state => state.departureCode,
  getArrCode: state => state.arrivalCode,
  getDateDep: state => state.dateDep,
  getDateArr: state => state.dateArr
};

const mutations = {
  SET_DEP_CITY(state, departureCity = null) {
    state.departureCity = departureCity;
  },
  SET_ARR_CITY(state, arrivalCity = null) {
    state.arrivalCity = arrivalCity;
  },
  SET_DEP_DATE(state, dateDep = null) {
    state.dateDep = dateDep;
  },
  SET_ARR_DATE(state, dateArr = null) {
    state.dateArr = dateArr;
  },
  SET_DEP_CODE(state, departureCode = null) {
    state.departureCode = departureCode;
  },
  SET_ARR_CODE(state, arrivalCode = null) {
    state.arrivalCode = arrivalCode;
  }
};

const actions = {
  async searchCity ({ commit, dispatch }, city = null) {
      try {
          return await axios.post(baseUrl+'city', city);
      } catch (e) {
          return e
      }
      //dispatch('notifications/handle', res, { root: true });
  },
  SET_DEP_CITY: ({ commit }, depCity = null) => {
    commit('SET_DEP_CITY', depCity);
  },
  SET_ARR_CITY: ({ commit }, arrCity = null) => {
    commit('SET_ARR_CITY', arrCity);
  },
  SET_DEP_CODE: ({ commit }, depCode = null) => {
    commit('SET_DEP_CODE', depCode);
  },
  SET_ARR_CODE: ({ commit }, arrCode = null) => {
    commit('SET_ARR_CODE', arrCode);
  },
  SET_DEP_DATE: ({ commit }, dateDep = null) => {
    commit('SET_DEP_DATE', dateDep);
  },
  SET_ARR_DATE: ({ commit }, dateArr = null) => {
    commit('SET_ARR_DATE', dateArr);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
