import Vue from 'vue'
import Vuex from 'vuex'

import city from './modules/city'
import passengers from './modules/passengers'
import requestData from './modules/requestData'
import searchStatus from './modules/searchStatus'
import tickets from './modules/tickets'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

    },

    actions: {

    },

    mutations: {

    },

    getters: {

    },

    modules: {
        city,
        passengers,
        requestData,
        searchStatus,
        tickets
    }
})

