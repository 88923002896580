const state = () => ({
    passengers: [
        {
            co: 'ADT',
            qt: 1
        },
        {
            co: 'CHD',
            qt: 0
        },
        {
            co: 'INF',
            qt: 0
        }
    ],
    pnrObj: {
        ADT: 1
    }
});

const getters = {
    getPassengersForRequest: state =>
        state.passengers.filter(e => e.qt !== 0)
};

const mutations = {
    SET_PASSENGERS_AMOUNT(state, passenger = null) {
        state.passengers = passenger;
    },
    SET_PASSENGERS_OBJECT(state, pnr = null) {
        state.pnrObj = pnr;
    },
    ADD_PASSENGERS (state, index = null) {
        state.passengers[index].qt++
    },
    DEDUCT_PASSENGERS (state, index = null) {
        state.passengers[index].qt--
    }
};

const actions = {
    SET_PASSENGERS_AMOUNT: ({ commit }, passenger = null) => {
        commit('SET_PASSENGERS_AMOUNT', passenger);
    },
    SET_PASSENGERS_OBJECT: ({ commit }, pnr = null) => {
        commit('SET_PASSENGERS_OBJECT', pnr);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
