import Vue from 'vue'
import App from './App.vue'
import store from './store'

import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'
import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.css'
import moment from 'moment'
import $ from 'jquery/dist/jquery.min'

if (process.browser) {
  window.$ = $;
}
import 'popper.js/dist/popper.min'
import 'vue-loaders/dist/vue-loaders.css'
import SuiVue from 'semantic-ui-vue'
import PortalVue from 'portal-vue';
//import 'bootstrap/dist/js/bootstrap.min'
import vuelidate from 'vuelidate'
import VueLoaders from 'vue-loaders'
import bFormSlider from 'vue-bootstrap-slider';
import 'bootstrap-slider/dist/css/bootstrap-slider.css'

Vue.use(bFormSlider)
Vue.use(VueLoaders);
//Vue.use(datePicker);
Vue.use(SuiVue);
Vue.use(PortalVue);
Vue.use(vuelidate);
// Vue.use(GoTop);
Vue.use(require('vue-moment'));

const datepickerOptions = {
  dateLabelFormat: 'dddd, MMMM D, YYYY',
  days: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
  daysShort: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  // days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  // daysShort: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  // monthNames: [
  //     'January',
  //     'February',
  //     'March',
  //     'April',
  //     'May',
  //     'June',
  //     'July',
  //     'August',
  //     'September',
  //     'October',
  //     'November',
  //     'December',
  // ],
  colors: {
    selected: '#4b77be',
    inRange: '#4b77be80',
    selectedText: '#fff',
    text: '#757575',
    inRangeBorder: '#4b77be80',
    disabled: '#75757582',
    hoveredInRange: '#4b77be47'
  },
  texts: {
    apply: 'Обратный билет не нужен',
    cancel: ' ',
  },
}

Vue.use(AirbnbStyleDatepicker, datepickerOptions)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(vuelidate);
Vue.use(VueI18n)
Vue.config.productionTip = false

Vue.filter( 'dateFormat', function (value, format = '', row = false) {
  if (!row) {
    return moment(value).format(format)
  }
  // Split
  let arr = value.split(':')
  return (arr[0] + ' hours, ' + parseInt(arr[1]) + ' mins.')
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#widget')
